<template>
  <upload></upload>
</template>

<script>
import upload from "@/components/upload";
export default {
  components: {
    upload,
  },
};
</script>
